import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const ListLink = props => (
  <li 
    style={{ 
      display: `inline-block`, 
      margin: 0, 
      alignSelf: `center` 
    }}
  >
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `cadetblue`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        display: `grid`,
        gridTemplateColumns: `3fr 1fr 1fr 1fr`,
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <ListLink to="/about">About</ListLink>
      <ListLink to="/">Projects</ListLink>
      <ListLink to="/contact">Contact</ListLink>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: `PropTypes.string`,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
