import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BlogSection from "../components/blogSection"

const styles = () => ({
  hide: {
    display: `none`
  }
})

const IndexPage = ({ children }) => (
  <Layout>
    <SEO title="Daniel Sack" />
    <div style={{ margin: `3rem auto`, maxWidth: 600, textAlign: `center`}}>
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem`, margin: `auto` }} className={styles.hide}>
        <Image />
      </div>
    </div>
    <BlogSection />
    <div style={{ margin: `3rem auto`, maxWidth: 600 }}>
    <h1>Hi! I'm building a fake Gatsby site as part of a tutorial!</h1>
    <p>
      What do I like to do? Lots of course but definitely enjoy building
      websites.
    </p>
  </div>
    <Link to="/blogposts/">View all posts</Link>
    <Link to="/contact/">Contact</Link>
  </Layout>
)

export default IndexPage
